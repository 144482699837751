<template>
  <v-container class="my-6 py-6">
    <h2>
      Administrar templates de administradores para carga masiva
    </h2>
    <v-row
      class="my-4"
      justify="center"
      align="center"
    >
      <v-col>
        <div v-if="distribuidores.length">
          <paginate
            name="products"
            :list="distribuidores"
            :per="perPage"
            class="pl-0"
          >
            <v-row justify="center" align="center">

              <v-col
                v-for="distribuidor in paginated('products')" 
                :key="distribuidor.id"
                class="mx-auto my-auto"
                md="4"
              >
                <v-card
                  max-width="300"
                  class="mx-auto my-4"
                >
                  <v-card-text>
                    <p class="display-1 text--primary">
                      {{ distribuidor.nombre_distribuidor }}
                    </p>
                    <v-row>
                      <v-col cols="10" class="pb-0">Template inventario:</v-col>
                      <v-col cols="2" class="pb-0">
                        <v-btn
                          icon
                          small
                          color="primary"
                          title="Subir archivo"
                          @click="openDialog('Template inventario', distribuidor.id)"
                        >
                          <v-icon>mdi-cloud-upload</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <template v-if="distribuidor.documentos_inventario.length > 0">
                          <v-chip
                            v-for="doc in distribuidor.documentos_inventario"
                            :key="doc.id"
                            color="secondary"
                            outlined
                            small
                            @click="downloadDocument(doc)"
                          >
                            <v-icon left small>
                              mdi-download-outline
                            </v-icon>
                            {{ doc.nombre_documento }}
                          </v-chip>
                        </template>
                        <v-chip
                          v-else
                          color="black"
                          outlined
                          small
                        >
                          Aún sin template
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="10" class="pb-0">Template precios:</v-col>
                      <v-col cols="2" class="pb-0">
                        <v-btn
                          icon
                          small
                          color="primary"
                          title="Subir archivo"
                          @click="openDialog('Template precios', distribuidor.id)"
                        >
                          <v-icon>mdi-cloud-upload</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <template v-if="distribuidor.documentos_precio.length > 0">
                          <v-chip
                            v-for="doc in distribuidor.documentos_precio"
                            :key="doc.id"
                            color="secondary"
                            outlined
                            small
                            @click="downloadDocument(doc)"
                          >
                            <v-icon left small>
                              mdi-download-outline
                            </v-icon>
                            {{ doc.nombre_documento }}
                          </v-chip>
                        </template>
                        <v-chip
                          v-else
                          color="black"
                          outlined
                          small
                        >
                          Aún sin template
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </paginate>
          <paginate-links
            for="products"
            :async="true"
            :limit="5"
            :hide-single-page="true"
            :classes="{
              'ul': 'pagination',
              'li': 'page-item',
              'li > a': 'page-link'
            }"
          >
          </paginate-links>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          Subir {{ title }} 
        </v-card-title>
        <v-card-text>
          <v-file-input
            v-model="fileToUpload"
            accept=".xlsx"
            solo
            label="Subir archivo"
            @click:clear="fileToUpload = null"
          >
            <template v-slot:append-outer>
              <v-btn
                color="primary"
                :disabled="fileToUpload === null"
                :loading="importing"
                @click="uploadFile"
              >
                Enviar
              </v-btn>
            </template>
          </v-file-input>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ApiAuth } from '@/api_utils/axios-base'
import axios from "axios"
import { mapState } from 'vuex'
export default {
  data() {
    return {
      distribuidores: [],
      paginate: ['products'],
      perPage: 3,
      dialog: false,
      importing: false,
      fileToUpload: null,
      title: '',
      selectedDistributor: null
    }
  },
  computed: {
    ...mapState("usuario", ["userData"]),
  },
  async created() {
    // this.distribuidores = await this.fetchDistributors()
    await this.getDocumentsOfDistributor()
  },
  methods: {
    async fetchDistributors() {
      try {
        const response = await ApiAuth.get('/personas/api/distribuidor/')
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener los distribuidores', error);
      }
    },
    async fetchDocumentsByDistributor(distributorId) {
      try {
        const response = await ApiAuth.post('/personas/documento-distribuidor', {
          id_distribuidor: distributorId
        })
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener documentos de distribuidor', error);
      }
    },
    async getDocumentsOfDistributor() {
      try {
        const distributors = await this.fetchDistributors()
        for (const distributor of distributors) {
          const documents = await this.fetchDocumentsByDistributor(distributor.id)
          // console.log('Documentos', documents);
          if (documents.documentos.length > 0) {
            const docs = documents.documentos
            const priceDocuments = docs.filter(d => d.nombre_documento.toLowerCase().includes('precio'))
            const inventoryDocuments = docs.filter(d => d.nombre_documento.toLowerCase().includes('inventario'))
            distributor.documentos_precio = priceDocuments ? priceDocuments : []
            distributor.documentos_inventario = inventoryDocuments ? inventoryDocuments : []
          } else {
            distributor.documentos_precio = []
            distributor.documentos_inventario = []
          }
        }
        this.distribuidores = distributors
        // console.log('distribuidores', this.distribuidores);
      } catch (error) {
        console.error('Error al intentar establecer documentos de algun distribuidor', error);
      }
    },
    async uploadFile() {
      try {
        this.importing = true
        // console.log('inventoryFile', this.inventoryFile.name);
        const fileName = this.fileToUpload.name
        const formData = new FormData()
        formData.append('File_1', this.fileToUpload)
        const responseUpload = await ApiAuth.post('/checkout/subir-archivo-factura', 
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          const responseUploadData = await responseUpload.data
          if (responseUploadData.mensaje.includes('Archivo guardado')) {
            const fileToImport = responseUploadData.archivos[0][`${fileName}`]
            const responseImport = await ApiAuth.post('/personas/crear-documento-distribuidor', {
              nombre_documento: fileName,
              url_documento: fileToImport,
              id_distribuidor: this.selectedDistributor,
              id_autor: this.userData.id
            })
            const responseImportData = await responseImport.data
            // console.log('responseImportData:', responseImportData);
            if (responseImportData.e) {
              console.log('No se pudo procesar el archivo: ' + responseImportData.e.join(', '))
              // console.log('crashImport:', this.crashImport);
            } else if (responseImportData.objeto) {
              // console.log(responseImportData.objeto)
            }
            //console.log('respuesta importResults: ', this.importResults)
          } else {
            //console.log('no entro al includes');
          }
        await this.getDocumentsOfDistributor()
        setTimeout(() => {
          this.importing = false
        }, 500)
        this.fileToUpload = null
        this.selectedDistributor = null
        this.dialog = false
      } catch (error) {
        
      }
    },
    async downloadDocument(doc) {
      try {
        // console.log('Descargar documento', doc);
        const documentUrl = doc.documento;
        const fileName = doc.nombre_documento;

        const response = await axios({
          url: documentUrl,
          method: "GET",
          responseType: "blob",
        });
        const responseData = await response.data;
        const fileblob = new Blob([responseData]);
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(fileblob)
        link.download = `${fileName}`
        link.click()
      } catch (error) {
        console.error('Error al intentar descargar un documento', error);
      }
    },
    openDialog(strTemplate, distributorId) {
      this.title = strTemplate
      this.selectedDistributor = distributorId
      this.dialog = true
    }
  }
}
</script>

<style>

</style>